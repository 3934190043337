import React, { useState, useEffect } from 'react';
import SubwayList from '../component/subway/SubwayList';
import * as client from '../lib/api';


const SubwayContiner = (props) => {
  const { setStatnFNm, setStatnTNm, setSubwayNo, setOutStnNum, setSubwayIndex } = props;
  
  const [subwayList, setSubwayList] = useState([]); // 지하철 리스트
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(""); // 좌표
  const [lon, setLon] = useState(""); // 좌표
  

  const listSubway = async (lat, lon) => {
    setIsLoading(true);
    try {
      // 지하철 리스트 조회
      const response = await client.fetchSubwayList(lat, lon);
      sessionStorage.setItem(`loc_addr`, response.data.fullAddress);  // 위치 주소

      setSubwayList(response.data.subwayList);
      setAddress(response.data.fullAddress);
      props.setAddress(response.data.fullAddress.substr(6));

      // 상세화면에 접속한 경우 지하철 역 명과 호선명을 저장
      const subwayName = sessionStorage.getItem(`__select_subway_name`);
      let subwayLineId = sessionStorage.getItem(`__select_subway_line_id`);

      // 상세에서 새로고침 하는 경우
      if (subwayName) {
        // 호선이 null인 경우도 있음
        if (subwayLineId === "null") {
          subwayLineId = null;
        }

        const subwayListData = response.data.subwayList;

        // 상세화면에서의 역 명과 리스트의 역명이 일치하는게 있는지 확인하기 위함
        let count = 0;

        for (let i = 0; i < subwayListData.length; i++) {
          const item = subwayListData[i];

          // 역명과 호선이 일치하는 경우
          if (item.stnKrNm === subwayName && item.lineId === subwayLineId) {
            // 지하열역 명, 호선으로 도착지 정보 조회
            const response2 = await client.fetchSubwayArrival(subwayName, subwayLineId);

            const subwayArrival = response2.data;

            var fName, tName;
            if (subwayArrival.length > 0) {
              fName = subwayArrival[0].statnFNm;  // 이전 역 이름
              tName = subwayArrival[0].statnTNm;  // 다음 역 이름

              setStatnFNm(fName);
              setStatnTNm(tName);
            }

            setSubwayNo(item.subwayNo);
            setOutStnNum(item.outStnNum);
            setSubwayIndex(subwayName);
            break;
          }

          count++;

          // 일치하는 역이 하나도 없는 경우
          if (count === subwayListData.length) {
            // 선택된 역과 호선을 제거
            sessionStorage.removeItem(`__select_subway_name`);
            sessionStorage.removeItem(`__select_subway_line_id`);

            // 적용된 스타일 (스크롤 방지)
            const styleElements = document.querySelectorAll('style');

            // NodeList를 배열로 변환
            const styleArray = Array.from(styleElements);

            // 배열의 마지막 요소 선택
            const lastStyleElement = styleArray.pop();

            // 마지막 요소가 존재하면 삭제
            if (lastStyleElement) {
              lastStyleElement.parentNode.removeChild(lastStyleElement);
            }

            // 상세화면 보이지 않도록 처리
            setSubwayIndex(null);
          }
        }
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }

  useEffect(() => {
    console.log('현재 위치 구하기');
    // 현재 위치 좌표 구해서, 지하철 목록 조회
    navigator.geolocation.getCurrentPosition((position) => {
      // GPS 좌표
      var lat = position.coords.latitude;
      var lon = position.coords.longitude;

      if (sessionStorage.getItem(`__change_lat`) || sessionStorage.getItem(`__change_lon`)) {
        lat = sessionStorage.getItem(`__change_lat`);
        lon = sessionStorage.getItem(`__change_lon`);

        console.log("변경 위치 : " + lat);
        console.log("변경 위치 : " + lon);
      }

      console.log('lat', lat);
      console.log('lon', lon);

      // 현재 좌표로 지하철 리스트 조회
      listSubway(lat, lon);
      setLat(lat);
      setLon(lon);

      // 지하철 상세화면에 좌표값을 넘기기 위함
      props.setLat(lat);
      props.setLon(lon);
    })
  }, []);

  // 로딩 상태 감지
  useEffect(() => {
    if (isLoading) {
      // 로딩중인 경우 잠시만 기다려 주세요 화면으로 가림 처리 후 뒤 페이지 스크롤 막기
      const styleElement = document.createElement('style');
      styleElement.textContent = 'html { overflow: hidden; }';
      document.head.appendChild(styleElement);
    } else {
      // 로딩이 완료된 이후 막은 스크롤 제거
      // 적용된 스타일 (스크롤 방지)
      const styleElements = document.querySelectorAll('style');

      // NodeList를 배열로 변환
      const styleArray = Array.from(styleElements);

      // 배열의 마지막 요소 선택
      const lastStyleElement = styleArray.pop();

      // 마지막 요소가 존재하면 삭제
      if (lastStyleElement) {
        lastStyleElement.parentNode.removeChild(lastStyleElement);
      }
    }
  }, [isLoading]);

  return (
    <main className="container">
      <div className="my-2 p-2 bg-body rounded shadow-sm">
        <SubwayList subwayList={subwayList} isLoading={isLoading} lat={lat} lon={lon} setStatnFNm={setStatnFNm} setStatnTNm={setStatnTNm} setSubwayNo={setSubwayNo} setOutStnNum={setOutStnNum} setSubwayIndex={setSubwayIndex} />
      </div>
    </main>

  )
}

export default SubwayContiner;