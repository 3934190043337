import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';

// history 객체 생성. 커스텀 히스토리를 생성할 때 사용
const history = createBrowserHistory();

// 애플리케이션이 렌더링될 HTML 요소를 참조, index.html에 있음
const root = document.getElementById('root');

// 브라우저 렌더링
ReactDOM.render(
  <BrowserRouter>
    <App history={history}/>
  </BrowserRouter>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();